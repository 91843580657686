<template>
  <div>
    <WebLoader v-if="loading" />
    <b-container id="mainContainer" align-v="center">
      <CustomBg />
      <b-row align-v="center" align-h="center">
        <b-col md="12" class="mt-4 mb-4">
          <div>
            <b-card id="cardLogin" class="scale-in-bl">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show" id="formLogin">
                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob">
                  <path fill="#00853e"
                    d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                    transform="translate(100 100)" />
                </svg>

                <h1 class="text-center mb-2">Add Turf</h1>
                <span class="text-center mb-4 other-account">Add your turf by filling the details below:</span>
                <b-row align-v="center" align-h="center">
                  <b-col md="6">
                    <InputField id="turf_name" v-model="form.turf_name" type="text" placeholder="Turf Name">
                    </InputField>
                  </b-col>

                  <b-col md="6">
                    <InputField id="turf_size" v-model="form.turf_size" type="text" placeholder="Turf Size">
                    </InputField>
                  </b-col>

                  <b-col md="6">
                    <b-form-group class="pb-3" id="input-group-3" label-for="turfImage">
                      <b-form-file id="turfImage" class="input" accept=".jpg, .png, .jpeg" v-model="form.turf_image"
                        placeholder="Upload Turf Image" required></b-form-file>
                    </b-form-group>
                  </b-col>

                  <b-col class="position-relative" md="6">
                    <b-form-group class="pb-3" id="input-group-1" label-for="turf_status">
                      <b-form-select v-model="form.turf_status" :options="statusOptions" class="option-setting"
                        style="position: relative"></b-form-select>
                    </b-form-group>
                    <div v-if="form.turf_status === 'inactive'" class="circle-red"></div>
                    <div v-if="form.turf_status === 'active'" class="circle-green"></div>
                  </b-col>


                  <b-row>
                    <b-col md="12">
                      <h2 class="text-center mb-2">Peak Days Details</h2>
                    </b-col>
                  </b-row>
                  <b-col md="12">
                    <label for="peak-days">Select Peak Days:</label>
                    <multiselect class="pb-3" v-model="form.selectedPeakDays" :options="days" :multiple="true"
                      :taggable="true" @tag="addTag" placeholder="Select one or more peak days" label="text"
                      track-by="value">
                    </multiselect>
                  </b-col>

                  <b-col md="4">
                    <label for="peak-day-peak-start-time">Peak Day Peak Start Time:</label>
                    <timePicker label="00:00 am/pm" label-for="peak-day-peak-start-time"
                      v-model="form.peak_day_peak_start_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="peak-day-peak-end-time">Peak Day Peak End Time:</label>
                    <timePicker label="00:00 am/pm" label-for="peak-day-peak-end-time"
                      v-model="form.peak_day_peak_end_time"></timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="peak-day-off-peak-start-time">Peak Day Off-Peak Start Time:</label>
                    <timePicker label="00:00 am/pm" label-for="peak-day-off-peak-start-time"
                      v-model="form.peak_day_off_peak_start_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="peak-day-off-peak-end-time">Peak Day Off-Peak End Time:</label>
                    <timePicker label="00:00 am/pm" label-for="peak-day-off-peak-end-time"
                      v-model="form.peak_day_off_peak_end_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="peak-day-peak-hours-charges">Peak Day Peak Hours Charges:</label>
                    <InputField id="peak_day_peak_hours_charges" v-model="form.peak_day_peak_hours_charges" type="text"
                      placeholder="0/-">
                    </InputField>
                  </b-col>

                  <b-col md="4">
                    <label for="peak-day-off-peak-hours-charges">Peak Day Off-Peak Hours Charges:</label>
                    <InputField id="peak_day_off_peak_hours_charges" v-model="form.peak_day_off_peak_hours_charges"
                      type="text" placeholder="0/-"></InputField>
                  </b-col>

                  <b-row>
                    <b-col md="12">
                      <h2 class="text-center mb-2">Off-Peak Days Details</h2>
                    </b-col>
                  </b-row>

                  <b-col md="12">
                    <label for="off-peak-days">Select Off-Peak Days:</label>
                    <multiselect class="pb-3" v-model="form.selectedoffPeakDays" :options="days" :multiple="true"
                      :taggable="true" @tag="addTag" placeholder="Select one or more off-peak days" label="text"
                      track-by="value">
                    </multiselect>
                  </b-col>

                  <b-col md="4">
                    <label for="off-peak-day-peak-start-time">Off-Peak Day Peak Start Time:</label>
                    <timePicker label="00:00 am/pm" label-for="off-peak-day-peak-start-time"
                      v-model="form.off_peak_day_peak_start_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="off-peak-day-peak-end-time">Off-Peak Day Peak End Time:</label>
                    <timePicker label="00:00 am/pm" label-for="off-peak-day-peak-end-time"
                      v-model="form.off_peak_day_peak_end_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="off-peak-day-off-peak-start-time">Off-Peak Day Off-Peak Start Time:</label>
                    <timePicker label="00:00 am/pm" label-for="off-peak-day-off-peak-start-time"
                      v-model="form.off_peak_day_off_peak_start_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="off-peak-day-off-peak-end-time">Off-Peak Day Off-Peak End Time:</label>
                    <timePicker label="00:00 am/pm" label-for="off-peak-day-off-peak-end-time"
                      v-model="form.off_peak_day_off_peak_end_time">
                    </timePicker>
                  </b-col>

                  <b-col md="4">
                    <label for="off-peak-day-peak-hours-charges">Off-Peak Day Peak Hours Charges:</label>
                    <InputField id="off_peak_day_peak_hours_charges" v-model="form.off_peak_day_peak_hours_charges"
                      type="text" placeholder="0/-">
                    </InputField>
                  </b-col>

                  <b-col md="4">
                    <label for="off-peak-day-off-peak-hours-charges">Off-Peak Day Off-Peak Hours Charges:</label>
                    <InputField id="off_peak_day_off_peak_hours_charges"
                      v-model="form.off_peak_day_off_peak_hours_charges" type="text"
                      placeholder="0/-">
                    </InputField>
                  </b-col>

                </b-row>

                <div  class="d-flex justify-content-center mt-3">
                  <a href="#" class="register-arrow-btn" @click="onSubmit">
                    <img class="arrow-img" src="@/assets/images/arrow.png" alt="" srcset="" />
                  </a>
                </div>

                <div class="d-flex justify-content-center flex-wrap mt-4 register g-2"></div>
              </b-form>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CustomBg from "@/components/CustomBg";
import axios from "axios";
import { BASE_URL } from "@/../config";
import Multiselect from "vue-multiselect";
import TimePicker from "@/components/TimePicker";
import InputField from "@/components/InputField";
export default {
  components: {
    CustomBg,
    TimePicker,
    Multiselect,
    InputField,
  },
  data() {
    return {
      loading: false,
      form: {
        vendor_id: this.$store.state.user.id,
        turf_name: "",
        turf_status: null,
        turf_image: null,
        turf_size: "",
        selectedPeakDays: [],
        peak_day_peak_start_time: null,
        peak_day_peak_end_time: null,
        peak_day_off_peak_start_time: null,
        peak_day_off_peak_end_time: null,
        peak_day_peak_hours_charges: "",
        peak_day_off_peak_hours_charges: "",
        selectedoffPeakDays: [],
        off_peak_day_peak_start_time: null,
        off_peak_day_peak_end_time: null,
        off_peak_day_off_peak_start_time: null,
        off_peak_day_off_peak_end_time: null,
        off_peak_day_peak_hours_charges: "",
        off_peak_day_off_peak_hours_charges: "",
      },
      selectedTime: "",

      days: [
        { value: "Monday", text: "Monday" },
        { value: "Tuesday", text: "Tuesday" },
        { value: "Wednesday", text: "Wednesday" },
        { value: "Thursday", text: "Thursday" },
        { value: "Friday", text: "Friday" },
        { value: "Saturday", text: "Saturday" },
        { value: "Sunday", text: "Sunday" },
      ],

      statusOptions: [
        { value: null, text: "Turf Status" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      show: true,
    };
  },
  mounted() {
    // console.log("Vendor Id is :", this.$store.state.user.id);
  },
  methods: {
    addTag(newTag) {
      console.log("New tag:", newTag);
      const tag = {
        text: newTag,
        value: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      if (this.form.selectedPeakDays) {
        this.days.push(tag);
        this.form.selectedPeakDays.push(tag);
      } else {
        this.days.push(tag);
        this.form.selectedoffPeakDays.push(tag);
      }
    },

    onSubmit(event) {
      event.preventDefault();
      // Set loading to true to show the loader
      this.loading = true;

      // Create a FormData object
      let formData = new FormData();
      // Append form data to the FormData object
      formData.append("vendor_id", this.form.vendor_id);
      formData.append("turf_name", this.form.turf_name);
      formData.append("turf_status", this.form.turf_status);
      formData.append("turf_image", this.form.turf_image);
      formData.append("turf_size", this.form.turf_size);
      formData.append(
        "peak_days",
        this.form.selectedPeakDays.map((day) => day.text).join(",")
      );
      formData.append(
        "peak_day_peak_start_time",
        this.form.peak_day_peak_start_time
      );
      formData.append(
        "peak_day_peak_end_time",
        this.form.peak_day_peak_end_time
      );
      formData.append(
        "peak_day_off_peak_start_time",
        this.form.peak_day_off_peak_start_time
      );
      formData.append(
        "peak_day_off_peak_end_time",
        this.form.peak_day_off_peak_end_time
      );
      formData.append(
        "peak_day_peak_hours_charges",
        this.form.peak_day_peak_hours_charges
      );
      formData.append(
        "peak_day_off_peak_hours_charges",
        this.form.peak_day_off_peak_hours_charges
      );
      formData.append(
        "off_peak_days",
        this.form.selectedoffPeakDays.map((day) => day.text).join(",")
      );
      formData.append(
        "off_peak_day_peak_start_time",
        this.form.off_peak_day_peak_start_time
      );
      formData.append(
        "off_peak_day_peak_end_time",
        this.form.off_peak_day_peak_end_time
      );
      formData.append(
        "off_peak_day_off_peak_start_time",
        this.form.off_peak_day_off_peak_start_time
      );
      formData.append(
        "off_peak_day_off_peak_end_time",
        this.form.off_peak_day_off_peak_end_time
      );
      formData.append(
        "off_peak_day_peak_hours_charges",
        this.form.off_peak_day_peak_hours_charges
      );
      formData.append(
        "off_peak_day_off_peak_hours_charges",
        this.form.off_peak_day_off_peak_hours_charges
      );

      axios
        .post(`${BASE_URL}/add-turf-details`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.$showSuccessMessageToast(response.data.message);
          this.onReset(event);
        })
        .catch((error) => {
          this.$showErrorMessageToast(error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onReset(event) {
      event.preventDefault();
      this.form.turf_name = "";
      this.form.turf_status = null;
      this.form.turf_image = null;
      this.form.turf_size = "";
      this.form.selectedPeakDays = [];
      this.form.peak_day_peak_start_time = null;
      this.form.peak_day_peak_end_time = null;
      this.form.peak_day_off_peak_start_time = null;
      this.form.peak_day_off_peak_end_time = null;
      this.form.peak_day_peak_hours_charges = "";
      this.form.peak_day_off_peak_hours_charges = "";
      this.form.selectedoffPeakDays = [];
      this.form.off_peak_day_peak_start_time = null;
      this.form.off_peak_day_peak_end_time = null;
      this.form.off_peak_day_off_peak_start_time = null;
      this.form.off_peak_day_off_peak_end_time = null;
      this.form.off_peak_day_peak_hours_charges = "";
      this.form.off_peak_day_off_peak_hours_charges = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style lang="scss">
.circle-red {
  position: absolute;
  top: 0.88rem;
  left: 6rem;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #df1620;
  border-radius: 50%;
}

.circle-green {
  position: absolute;
  top: 0.88rem;
  left: 5.15rem;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #6ec531;
  border-radius: 50%;
}

.password-input-group-append {
  position: absolute;
  right: 0;
}

.password-input-group .btn {
  border-color: none;
  background-color: transparent;
  border: none;
}

#mainContainer {
  width: 100%;
  height: auto;
  position: relative;
}

@import "@/assets/scss/variables.scss";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/fonts.scss";

.custom-select {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 10px !important;
  line-height: 1.5;
  color: #555555 !important;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s, ease-in-out;
}

.custom-file-input {
  display: none;
}

.custom-file-label {
  cursor: pointer;
  // margin-top: 0.4rem;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #dee2e6;
  /* Border style */
  border-radius: 10px;
  background-color: #fff;
  /* Background color */
  color: #555555;
}

.upload-btn {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 10px !important;
  background-color: #404041 !important;
  /* Background color */
}

.upload-btn:hover {
  background-color: #555555 !important;
  /* Background color */
}

body {
  font-family: $Poppins !important;
}

.g-2 {
  gap: 0.2rem;
}

.arrow-img {
  height: 3rem;
}

#cardLogin {
  border-radius: 15px;
  box-shadow: 0px 0px 10px $gray;

  #formLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
      color: $primary;
      font-weight: 700;
      font-family: $Poppins;
    }

    h2 {
      font-size: 1.2rem;
      color: $lightBlack;
      font-weight: 600;
      font-family: $Poppins;
    }

    .other-account {
      color: $gray;
    }

    .icons {
      width: 30px;
      cursor: pointer;
    }

    .input {
      border-radius: 10px;
    }

    .remember {
      color: $lightBlack;
    }

    .blob {
      position: absolute;
      top: -15%;
      left: -12%;
      width: 150px;
      opacity: 0.2;
    }
  }

  .forgotPassword {
    color: $gray;
    text-decoration: none;

    &:hover {
      color: $primary;
      transition: 0.5s ease-in-out;
    }
  }

  .register-arrow-btn {
    border-radius: 15px;
    background: $primary;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: $secondary;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }
  }

  .register {
    span {
      color: $lightBlack;
    }

    .loginAccount {
      color: $primary;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 767.5px) {
  .custom-file-label {
    margin-top: 0px !important;
  }
}
</style>
