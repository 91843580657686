<template>
  <div class="my-4">
    <WebLoader v-if="loading" />
    <h2 class="featured-heading">FEATURED TURF</h2>
    <h5 class="featured-subheading">
      Book a turf with the best amenities and services in your town.
    </h5>
    <b-container>
      <VueSlickCarousel v-bind="settings" class="main-carousel">
        <div class="card-carousel-card" v-for="item in filteredFeaturedClubs" :key="item.clubId">
          <div class="image-container">
            <img
              :src="item.imageUrl || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5Z8G3rhtGpBy2zaObOYpSQ7mb3y6yF114pA&s'"
              class="hoverable image-fluid" />
          </div>
          <div class="card-carousel-card-footer">
            <p>{{ item.clubName }}</p>
            <div class="tag-container">
              <p class="tag" v-for="(facilities, index) in item.facilities" :key="index"
                :class="index > 0 ? 'secondary' : ''">
                {{ item.facilities ? facilities : "No Facilities" }}
              </p>
            </div>

            <div class="d-flex pt-3">
              <b-button @click="() => goToSlot(item.clubId, item.user_id)" class="">
                Book Now
              </b-button>
            </div>

          </div>
        </div>
      </VueSlickCarousel>
    </b-container>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapActions, mapGetters } from "vuex";
import WebLoader from "@/components/WebLoader.vue";

export default {
  name: "FeaturedTurf",
  components: { VueSlickCarousel, WebLoader },
  data() {
    return {
      loading: false,
      settings: {
        dots: true,
        infinite: false,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["vendors"]),
    filteredFeaturedClubs() {
      // Filter and flatten vendors data
      console.log("Vendors are:", this.vendors);
      return this.vendors.flatMap((area) =>
        area.turf_details.filter((vendor) => vendor.is_feature === "yes")
      );
    },
  },
  methods: {
    ...mapActions(["fetchVendors", "fetchTurfDetails"]),
    async goToSlot(club_id, user_id) {
      // console.log("Area ID:", area_id);
      // console.log("Club ID:", club_id);
      // console.log("User ID:", user_id);
      this.loading = true;

      //  if in local storage there is no key user then give error message
      if (!localStorage.getItem("user")) {
        this.$showErrorMessageToast("Please login to book a turf");
        this.loading = false;
        return;
      }

      // const vendor = this.vendors.find((vendor) => vendor.id === area_id);
      // console.log('Vendor is:', vendor.turf_details);

      // const turf = vendor.turf_details.find((turf) => turf.clubId === club_id);
      // console.log('Turf is:', turf);

      // if (turf.is_available === "no") {
      //   this.$showErrorMessageToast("The turf is currently unavailable");
      //   this.loading = false;
      //   return;
      // }

      await this.fetchTurfDetails(user_id);
      this.$router.push({
        name: "turf",
        params: { club_id },
      });
      this.loading = false;
    },
  },
  mounted() {
    // Fetch vendors if they are not already in the store
    if (this.vendors.length === 0) {
      this.$store.dispatch("fetchVendors");
    }
  },
};
</script>

<style>
.slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.slick-list {
  padding-top: 2rem !important;
  /* padding: 1rem !important; */
}

.slick-dots {
  position: static;
}

.slick-dots li button:before {
  font-size: 10px !important;
}

.card-carousel-card {
  cursor: pointer;
  box-shadow: 0 4px 15px 0 rgba(40, 44, 53, 0.06),
    0 2px 2px 0 rgba(40, 44, 53, 0.08);
  background-color: #fff;
  border-radius: 10px;
  /* z-index: 3; */
  margin-bottom: 2px;
  width: auto !important;
  max-width: 390px;
}

.card-carousel-card:first-child {
  /* margin-left: 40px; */
}

.card-carousel-card:last-child {
  /* margin-right: 0; */
}

.card-carousel-card img {
  vertical-align: bottom;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: opacity 150ms linear;
  user-select: none;
  max-width: 300px;
  max-height: 250px;
}

.card-carousel-card img:hover {
  opacity: 0.7;
  transition: opacity 0.1s ease-in-out;
}

/* Define the animation for the transition */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
    /* Slide in to the final position */
  }
}

.card-carousel-card .image-container {
  position: relative;
}

.card-carousel-card .book-now-button {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none !important;
  cursor: pointer;
  /* animation: slideInFromBottom 1s ease-in-out;  */
}

.card-carousel-card .book-now-button button:hover {
  background: #404041;
  /* Change the background color on hover */
  border: transparent !important;
}

.card-carousel-card:hover .book-now-button {
  display: block;
}

.card-carousel-card-footer {
  border-top: 0;
  padding: 7px 15px;
}

.card-carousel-card-footer p {
  padding: 3px 0;
  margin: 0;
  margin-bottom: 2px;
  font-size: 19px;
  font-weight: 500;
  color: #2c3e50;
  user-select: none;
}

.card-carousel-card-footer p.tag {
  font-size: 11px;
  font-weight: 300;
  padding: 4px;
  background: rgba(40, 44, 53, 0.06);
  display: inline-block;
  position: relative;
  margin-left: 4px;
  color: #666a73;
}

.card-carousel-card-footer p.tag:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent rgba(40, 44, 53, 0.06) transparent transparent;
  border-style: solid;
  border-width: 8px 12px 12px 0;
}

.card-carousel-card-footer p.tag.secondary {
  margin-left: 0;
  border-left: 1.45px dashed white;
}

.card-carousel-card-footer p.tag.secondary:before {
  display: none !important;
}

.card-carousel-card-footer p.tag:after {
  content: "";
  position: absolute;
  top: 8px;
  left: -3px;
  float: left;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: white;
  box-shadow: 0px 0px 0px #004977;
}

h1 {
  font-size: 3.6em;
  font-weight: 100;
  text-align: center;
  margin-bottom: 0;
  color: #333;
}

.featured-heading {
  font-weight: 700;
  color: #404041;
  text-align: center;
}

.featured-subheading {
  font-weight: 500;
  color: #9999;
  text-align: center;
}

.featured-heading {
  font-weight: 700;
  color: #404041;
  text-align: center;
  margin: 0px;
}

.featured-subheading {
  font-weight: 500;
  color: #00853e;
  text-align: center;
  margin: 0px;
}

.my-4 {
  overflow-x: hidden;
}

.tag-container {
  display: flex;
  max-width: 240px;
  /* flex-wrap: wrap; */
  overflow: auto;
}

/* want to mae scroll bar custom */
.tag-container::-webkit-scrollbar {
  height: 1px !important;
}

.tag-container::-webkit-scrollbar-thumb {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.tag-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.tag-container::-webkit-scrollbar-thumb:hover {
  background-color: #aeaeae;
}



/* @media screen and (max-width: 768px) {
  .card-carousel-card {
    max-width: 300px;
  }
  .card-carousel-card img {
    height: 200px;
  }
  .card-carousel-card-footer p {
    font-size: 16px;
  }
  .card-carousel-card-footer p.tag {
    font-size: 10px;
  }
} */

/* @media screen and (max-width: 600px) {
    .card-carousel-card {
      max-width: 250px;
    }
    .card-carousel-card img {
      height: 150px;
    }
    .card-carousel-card-footer p {
      font-size: 14px;
    }
    .card-carousel-card-footer p.tag {
      font-size: 9px;
    }
  } */

/* @media screen and (max-width: 480px) {
    .card-carousel-card {
      max-width: 200px;
    }
    .card-carousel-card img {
      height: 150px;
    }
    .card-carousel-card-footer p {
      font-size: 14px;
    }
    .card-carousel-card-footer p.tag {
      font-size: 9px;
    }

  } */

/* @media screen and (max-width: 320px) {
    .card-carousel-card {
      max-width: 300px;
    }
    .card-carousel-card img {
      height: 100px;
    }
    .card-carousel-card-footer p {
      font-size: 12px;
    }
    .card-carousel-card-footer p.tag {
      font-size: 8px;
    }
  } */
</style>
