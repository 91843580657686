<template>
    <div class="customer-page my-5">
        <WebLoader v-if="loading" />

        <b-container>
             <!-- <div 
                class="d-flex justify-content-between align-items-center"
                style="margin-bottom: 1rem;"
            >
                <h4 v-if="!showBookings">Customers</h4>
                <h4 v-else @click="backToCustomers">
                    <i class="fas fa-arrow-left"></i>
                    Bookings
                </h4>
                <b-dropdown variant="primary" text="Filter">
                    <b-dropdown-item @click="filterByDate('today')">Today</b-dropdown-item>
                    <b-dropdown-item @click="filterByDate('thisWeek')">This Week</b-dropdown-item>
                    <b-dropdown-item @click="filterByDate('thisMonth')">This Month</b-dropdown-item>
                    <b-dropdown-item @click="filterByDate('sixMonth')">Six Month</b-dropdown-item>
                    <b-dropdown-item @click="filterByDate('custom')">Custom</b-dropdown-item>
                </b-dropdown>
            </div> -->
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-header>
                            <b-row>
                                <b-col v-if="!showBookings">
                                    <h4>Customers</h4>
                                </b-col>
                                <b-col v-else>
                                    <h4 @click="backToCustomers">
                                        <i class="fas fa-arrow-left"></i>
                                        Bookings
                                    </h4>
                                    <!-- <b-button class="btn-sm" variant="secondary" >Back</b-button> -->
                                </b-col>
                   
                            </b-row>
                        </b-card-header>

                        <!-- Customers Table -->
                        <b-table v-if="!showBookings" striped hover :items="customers" :fields="fields" show-empty>
                            <template v-slot:cell(actions)="data">
                                <i class="fas fa-eye" @click="viewBookings(data.item)"></i>
                            </template>
                        </b-table>

                        <!-- Bookings Table -->
                        <b-table v-if="showBookings" striped hover :items="bookings" :fields="bookingFields" show-empty>

                        </b-table>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/../config";
export default {
    data() {
        return {
            loading: false,
            showBookings: false,
            customers: [],
            bookings: [],
            fields: [
                { key: "name", label: "Name" },
                { key: "email", label: "Email" },
                { key: "contact_number", label: "Phone" },
                { key: "created_at", label: "Created at" },
                { key: "actions", label: "Actions" },
            ],
            bookingFields: [
                { key: "date", label: "Date" },
                { key: "time_slot_ids", label: "Time Slots" },
                { key: "total_amount", label: "Amount" },
                { key: "booking_status", label: "Status" },
            ],
        };
    },
    mounted() {
        this.fetchAllCustomers();
    },
    methods: {
        viewBookings(customer) {
            const customerId = customer.id;
            this.loading = true;

            axios
                .get(`${BASE_URL}/get-customer-booking/${customerId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                })
                .then((response) => {
                    if (response.data.success) {
                        this.bookings = response.data.data;
                        this.showBookings = true;
                    } else {
                        console.log("No bookings found for customer:", customer.full_name);
                    }
                })
                .catch((error) => {
                    console.error(`Error fetching bookings for customer ${customer.full_name}:`, error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        backToCustomers() {
            this.showBookings = false;
        },
        cancelBooking(bookingId) {
            console.log("Cancelling booking with ID:", bookingId);
            // Handle booking cancellation logic here
        },
        filterByDate(date) {
            console.log(date);
        },
        fetchAllCustomers() {
            this.loading = true;
            axios
                .get(`${BASE_URL}/all-customers`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                })
                .then((response) => {
                    this.customers = response.data.data;
                })
                .catch((error) => {
                    console.log("Error fetching customer details:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>



<style scoped>
.fas {
    cursor: pointer;
}

.fa-eye,
.fa-highlighter {
    margin-inline: .5rem;
    background: #404041;
    padding: .5rem;
    border-radius: .5rem;
    color: white;
}

.fa-eye:hover,
.fa-highlighter:hover {
    background: #a4a4a4;
}

.card-body {
    padding: 0;
}

.table-responsive {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0;
    max-height: 500px;
    margin-bottom: 0;
}

.table-responsive::-webkit-scrollbar {
    width: 10px;
    /* Width of vertical scrollbar */
    height: 10px;
    /* Height of horizontal scrollbar */
}

/* Track - background of the scrollbar */
.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle (thumb) */
.table-responsive::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid transparent;
}

/* Handle (thumb) on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Firefox */
.table-responsive {
    scrollbar-width: thin;
    /* Set the scrollbar width */
    scrollbar-color: #888 #f1f1f1;
    /* Scrollbar thumb and track color */
}

.table-responsive::-webkit-scrollbar-button {
    display: none;
}

.table {
    margin-bottom: 0 !important;
}

::v-deep td {
    vertical-align: middle;
    font-size: 14px;
}

.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
}

h4 {
    margin: 0 !important;
}
</style>