<template>
  <b-container class="my-3">
    <div class="row">
      <div class="col">
        <BookingPayment />
      </div>
    </div>
  </b-container>
</template>

<script>
import BookingPayment from "@/components/Customer/BookingPayment.vue";

export default {
  name: "HomeView",
  components: {
    BookingPayment,
  },
};
</script>
