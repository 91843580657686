<template>
  <b-form-group
    class="pb-3"
    id="input-group-1"
    :label-for="id"
  >
    <b-form-input
      :id="id"
      class="input"
      :value="stringValue"
      @input="$emit('input', $event)"
      :type="type"
      :placeholder="placeholder"
      :disabled="isDisabled"
    ></b-form-input>
  </b-form-group>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number], // Accept both String and Number
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    stringValue() {
      return String(this.value); // Convert the value to a string
    },
  },
}
</script>
