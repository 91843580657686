<template>
    <div class="vendor-page my-5">
        <WebLoader v-if="loading" />
        <b-container>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-header>
                            <b-row>
                                <b-col v-if="!showBookings">
                                    <h4>Vendors</h4>
                                </b-col>
                                <b-col v-else>
                                    <h4>
                                        <i class="fas fa-arrow-left" @click="backToVendors"></i>
                                        Bookings
                                    </h4>
                                </b-col>
                            </b-row>
                        </b-card-header>

                        <!-- Vendors Table -->
                        <div class="table-responsive">
                            <b-table v-if="!showBookings" striped hover :items="vendors" :fields="fields" show-empty>
                                <template v-slot:cell(editable)="data">
                                    <b-form-checkbox v-model="data.item.isEditable"></b-form-checkbox>
                                </template>

                                <!-- Availability toggle -->
                                <template v-slot:cell(vendor_status)="data">
                                    <toggle-button v-model="data.item.vendor_status"
                                        :disabled="!data.item.isEditable"></toggle-button>
                                </template>

                                <!-- Approved toggle -->
                                <template v-slot:cell(is_approved)="data">
                                    <toggle-button v-model="data.item.is_approved"
                                        :disabled="!data.item.isEditable"></toggle-button>
                                </template>

                                <!-- Percentage input -->
                                <template v-slot:cell(admin_percentage)="data">
                                    <b-form-input v-model="data.item.admin_percentage" type="text"
                                        :disabled="!data.item.isEditable"></b-form-input>
                                </template>

                                <!-- Update button -->
                                <template v-slot:cell(actions)="data">
                                    <!-- <b-button class="btn-sm" @click="updateVendor(data.item)">Update</b-button> -->
                                    <!-- <b-button class="btn-sm" @click="viewBookings(data.item)">View -->
                                    <i class="fas fa-eye" @click="viewBookings(data.item)"></i>
                                    <i class="fas fa-highlighter" @click="updateVendor(data.item)"></i>
                                </template>


                            </b-table>

                            <!-- Bookings Table -->
                            <b-table v-if="showBookings" striped hover :items="bookings" :fields="bookingFields"
                                show-empty>
                            </b-table>
                        </div>

                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>


<script>
import { BASE_URL } from "@/../config";
import axios from "axios";

export default {
    data() {
        return {
            fields: [
                { key: "editable", label: "Edit" },
                { key: "id", label: "ID" },
                { key: "vendor_name", label: "Name" },
                { key: "club_name", label: "Club" },
                { key: "email", label: "Email" },
                { key: "contact_number", label: "Phone" },
                { key: "area_name", label: "Area" },
                { key: "cnic_number", label: "CNIC" },
                { key: "ntn_number", label: "NTN" },
                { key: "is_approved", label: "Approved" },
                { key: "vendor_status", label: "Status" },
                { key: "admin_percentage", label: "Percentage" },
                { key: "actions", label: "Actions" },
            ],
            bookingFields: [
                { key: "date", label: "Date" },
                { key: "time_slot_ids", label: "Time Slots" },
                { key: "total_amount", label: "Amount" },
                { key: "booking_status", label: "Status" },
            ],
            vendors: [],
            bookings: [],
            loading: false,
            showBookings: false, // Controls view toggle
        };
    },
    mounted() {
        this.fetchAllVendor();
    },
    methods: {
        viewBookings(vendor) {
            const vendorId = vendor.id;
            this.loading = true;
            axios
                .get(`${BASE_URL}/get-vendor-booking/${vendorId}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                })
                .then((response) => {
                    if (response.data.success) {
                        this.bookings = response.data.data;
                        this.showBookings = true;
                    } else {
                        console.log("No bookings found for vendor:", vendor.vendor_name);
                    }
                })
                .catch((error) => {
                    console.error(`Error fetching bookings for vendor ${vendor.vendor_name}:`, error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        backToVendors() {
            this.showBookings = false;
        },

        updateVendor(vendor) {
            if (!vendor.isEditable) {
                this.$showErrorMessageToast("Please tick the checkbox to edit this vendor");
                return;
            }
            const payload = {
                vendor_id: vendor.id,
                statuses: {
                    is_approved: vendor.is_approved ? "yes" : "no",
                    vendor_status: vendor.vendor_status ? "Active" : "Inactive",
                },
                admin_percentage: vendor.admin_percentage || 0,
            };
            console.log("Updating vendor:", payload);
            axios
                .patch(`${BASE_URL}/vendor/update-status`, payload, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                })
                .then((response) => {
                    console.log("Vendor updated successfully:", response.data);
                    this.$showSuccessMessageToast("Vendor updated successfully");
                    this.fetchAllVendor();
                })
                .catch((error) => {
                    console.error("Error updating vendor:", error);
                });
        },

        filterByDate(date) {
            console.log("Filtering by date:", date);
        },
        fetchAllVendor() {
            this.loading = true;
            axios
                .get(`${BASE_URL}/all-vendors`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                })
                .then((response) => {
                    // Convert "yes" and "no" to true/false
                    this.vendors = response.data.data.map(vendor => {
                        return {
                            ...vendor,
                            is_approved: vendor.is_approved === 'yes' ? true : false,
                            vendor_status: vendor.vendor_status === 'Active' ? true : false,
                            isEditable: false,

                        };
                    });
                })
                .catch((error) => {
                    console.log("Error fetching vendor details:", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

    },
};
</script>

<style scoped>
.custom-checkbox {
    cursor: pointer;
}

.fas {
    cursor: pointer;
}

.fa-eye,
.fa-highlighter {
    margin-inline: .5rem;
    background: #404041;
    padding: .5rem;
    border-radius: .5rem;
    color: white;
}

.fa-eye:hover,
.fa-highlighter:hover {
    background: #a4a4a4;
}

.card-body {
    padding: 0;
}

.table-responsive {
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
    margin-bottom: 0;
    max-height: 500px;
}

.table-responsive::-webkit-scrollbar {
    width: 10px;
    /* Width of vertical scrollbar */
    height: 10px;
    /* Height of horizontal scrollbar */
}

/* Track - background of the scrollbar */
.table-responsive::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle (thumb) */
.table-responsive::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid transparent;
}

/* Handle (thumb) on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/* Firefox */
.table-responsive {
    scrollbar-width: thin;
    /* Set the scrollbar width */
    scrollbar-color: #888 #f1f1f1;
    /* Scrollbar thumb and track color */
}

.table-responsive::-webkit-scrollbar-button {
    display: none;
}

::v-deep td {
    vertical-align: middle;
    font-size: 14px;
}

/* ::v-deep .vue-js-switch .v-switch-label {
    display: none !important;
} */

.btn-sm {
    padding: 0.25rem 0.5rem !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
}

h4 {
    margin: 0 !important;
}
</style>