// store/index.js
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { BASE_URL } from "../../config";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null, // Initialize user state
    userName: "", // Initialize userName state
    token: "", // Initialize token state
    vendors: [], // Initialize vendors state
    turfDetails: [], // Initialize turfDetails state
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      // console.log("User Data is:", user.role_type);
    },
    clearUser(state) {
      state.user = null;
      state.userName = "";
      state.token = "";
    },
    setUserName(state, userName) {
      state.userName = userName; // Mutation to set userName
    },
    setToken(state, token) {
      state.token = token; // Mutation to set token
    },
    setVendors(state, vendors) {
      state.vendors = vendors;
    },
    setTurfDetails(state, details) {
      state.turfDetails = details;
    },
  },
  actions: {
    updateUserName({ commit }, userName) {
      commit("setUserName", userName); // Action to update userName
    },
    updateToken({ commit }, token) {
      commit("setToken", token); // Action to update token
    },
    logout({ commit, state }) {
      // Send bearer token with the request
      axios
        .post(
          `${BASE_URL}/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(() => {
          // Clear user-related state in the store
          commit("clearUser");
          commit("setVendors", []); // Clear vendors
          commit("setTurfDetails", []); // Clear turf details
          // Optionally, redirect to login page
          // router.push({ name: 'login' });
        })
        .catch((error) => {
          console.error("Logout error:", error);
          // Handle error
        });
    },
    async fetchVendors({ commit }) {
      try {
        const response = await axios.get(`${BASE_URL}/get-all-vendors`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const data = response.data.data;

        const vendors = Object.keys(data).map((area, index) => ({
          id: index + 1,
          title: area,
          turf_details: data[area].map((vendor) => ({
            facilities: vendor.facilities,
            clubId: vendor.id,
            clubName: vendor.full_name,
            user_id: vendor.user_id,
            turfId: vendor.turf_id,
            imageUrl: vendor.logo_path,
            description: vendor.email,
            is_available: vendor.is_available,
            is_feature: vendor.is_feature,
          })),
        }));
        console.log("Vendors:", vendors);
        console.log("Facilities:", vendors[0].turf_details[0].facilities);
        commit('setVendors', vendors);
      } catch (error) {
        console.error("Error fetching vendors:", error);
      }
    },
    async fetchTurfDetails({ commit }, userId) {
      try {
        const response = await axios.get(`${BASE_URL}/get-vendor-turfs/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const turfDetails = response.data.data;
        console.log("Turf Details are:", turfDetails);
        commit('setTurfDetails', turfDetails);
      } catch (error) {
        console.error("Error fetching turf details:", error);
      }
    },
  },
  getters: {
    vendors: state => state.vendors,
    turfDetails: state => state.turfDetails,
  },
  plugins: [createPersistedState()],
});
