<template>
  <b-container id="mainContainer" align-v="center">
    <WebLoader v-if="loading" />
    <CustomBg />
    <b-row align-v="center" align-h="center">
      <b-col md="6">
        <!-- <FormLogin /> -->
        <div>
          <b-card id="cardLogin" class="scale-in-bl">
            <b-form
              @submit="onSubmit"
              @reset="onReset"
              v-if="show"
              id="formLogin"
            >
              <svg
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
                class="blob"
              >
                <path
                  fill="#00853e"
                  d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                  transform="translate(100 100)"
                />
              </svg>

              <h1 class="text-center mb-3">Login</h1>
              <h2 class="text-center mb-4">Welcome back!</h2>
              <!-- <b-img src="../assets/images/logo.png" fluid class="logoLogin mb-4" /> -->
              <div class="d-flex justify-content-center mb-4">
                <img src="../assets/images/google.png" fluid class="icons" />
                <img
                  src="../assets/images/facebook.png"
                  fluid
                  class="icons mx-4"
                />
                <img src="../assets/images/twitter.png" fluid class="icons" />
              </div>
              <span class="text-center mb-4 other-account"
                >Or log in with your account</span
              >
              <b-form-group class="pb-3" id="input-group-1" label-for="email">
                <b-form-input
                  id="email"
                  class="input"
                  v-model="form.email"
                  type="email"
                  placeholder="E-mail"
                  required
                ></b-form-input>
              </b-form-group>

              <!-- <b-form-group class="pb-3" id="input-group-2" label-for="input-2">
                <b-form-input
                  id="passord"
                  class="input"
                  v-model="form.password"
                  placeholder="Password"
                  type="password"
                  required
                ></b-form-input>
              </b-form-group> -->

              <!--  Password -->
              <b-form-group
                class="pb-3"
                id="input-group-2"
                label-for="password"
              >
                <b-input-group>
                  <b-form-input
                    id="password"
                    class="input"
                    v-model="form.password"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="Password"
                    required
                  ></b-form-input>
                  <!-- Show/Hide Password Button -->
                  <b-input-group-append class="eye-apend">
                    <button
                      class="btn eye-btn"
                      type="button"
                      @click="showPassword = !showPassword"
                    >
                      <i
                        class="fas"
                        :class="{
                          'fa-eye': showPassword,
                          'fa-eye-slash': !showPassword,
                        }"
                      ></i>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <div class="d-flex justify-content-between flex-wrap">
                <b-form-checkbox v-model="form.remember_me" class="remember"
                  >Remember me</b-form-checkbox
                >
                <div>
                  <router-link to="/forgot-password" class="forgotPassword"
                    >Forgot password ?</router-link
                  >
                </div>
              </div>

              <!-- <div class="d-flex justify-content-center mt-3">
          <b-button type="submit" variant="primary" class="btn-login"
            >Entrar <font-awesome-icon icon="arrow-right" class="arrow"
          /></b-button>
        </div> -->

              <div @click="onSubmit" class="d-flex justify-content-center mt-3">
                <a href="#" class="login-arrow-btn">
                  <img
                    class="arrow-img"
                    src="../assets/images/arrow.png"
                    alt=""
                    srcset=""
                  />
                </a>
              </div>

              <div
                class="d-flex justify-content-center flex-wrap mt-4 register g-2"
              >
                <span class="mr-2">Don't have an account yet? </span>
                <router-link to="/register" class="createAccount">
                  Create an account</router-link
                >
              </div>
            </b-form>
          </b-card>
        </div>
      </b-col>
      <b-col md="6">
        <img
          src="../assets/images/business.png"
          fluid
          class="businessImg scale-in-bottom"
          center
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import CustomBg from "../components/CustomBg";
import "vue-toastification/dist/index.css";
import { BASE_URL } from "../../config";
import WebLoader from "./WebLoader.vue";

// import store from "../store/index";
export default {
  components: {
    CustomBg,
    WebLoader,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        remember_me: false,
        // ... (other form fields)
      },
      show: true,
      showPassword: false,
      loading: false,
    };
  },
  methods: {
    onSubmit(event) {
      this.loading = true;
      event.preventDefault();
      axios
        .post(`${BASE_URL}/login`, this.form)
        .then((response) => {
          localStorage.setItem("token", response.data.data.token.token);
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          this.$store.commit("setUser", response.data.data.user);
          this.$router.push({ name: "home" });
          this.$showSuccessMessageToast(response.data.message);
          console.log('RResponse is:',response);
        })
        .catch((error) => {
          console.error("API error:", error);
          this.$showErrorMessageToast(error.response.data.error);
        }).
        finally(() => {
          this.loading = false;
          this.onReset(event);
        });
    },

    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.password = "";
      this.form.remember_me = false;
      // ... (reset other form fields)

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/animations.scss";
@import "../assets/scss/variables.scss";
@import "../assets/scss/fonts.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";

.eye-apend {
  position: absolute;
  right: 0;
}

.input-group .btn {
  border-color: none;
  border: none;
}

.input {
  z-index: 1 !important;
}

.fa-eye,
.fa-eye-slash {
  color: $gray;
  cursor: pointer;
}

#mainContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.g-2 {
  gap: 0.2rem;
}

body {
  font-family: $Poppins !important;
}

.arrow-img {
  height: 3rem;
}

#cardLogin {
  border-radius: 15px;
  box-shadow: 0px 0px 10px $gray;

  #formLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
      color: $primary;
      font-weight: 700;
      font-family: $Poppins;
    }

    h2 {
      font-size: 1.2rem;
      color: $lightBlack;
      font-weight: 600;
      font-family: $Poppins;
    }

    .other-account {
      color: $gray;
    }

    .icons {
      width: 30px;
      cursor: pointer;
    }

    .input {
      border-radius: 10px;
    }

    .remember {
      color: $lightBlack;
      display: flex;
      align-items: center;
      gap: 0.2rem;
    }

    .blob {
      position: absolute;
      top: -15%;
      left: -12%;
      width: 150px;
      opacity: 0.2;
    }
  }

  /* .logoLogin {
    margin: 0 auto;
    width: 100px;
  } */

  .forgotPassword {
    color: $gray;
    text-decoration: none;

    &:hover {
      color: $primary;
      transition: 0.5s ease-in-out;
    }
  }

  /* .btn-login {
    width: 200px;
    background-color: $primary;
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 10px;

    &:hover {
      background-color: $secondary;
      transition: 0.5s ease-in-out;
    }
  } */

  .login-arrow-btn {
    // width: 100px;
    border-radius: 15px;
    background: $primary;
    // color: #fff;
    // font-size: 60px;
    // padding: 5px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: $secondary;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }
  }

  .register {
    span {
      color: $lightBlack;
    }

    .createAccount {
      color: $primary;
      text-decoration: none;
    }
  }
}
</style>
