<template>
  <div id="app">
    <!-- <NavbarView /> -->
    <router-view />
    <!-- <TapTop /> -->
    <!-- <FooterView /> -->
    <!-- v-if="!isLoginPage" -->
  </div>
</template>

<script>
// import NavbarView from "./components/NavbarView.vue";
// import FooterView from "./components/FooterView.vue";
// import TapTop from "./components/TapTop.vue";

export default {
  name: "App",
  components: {
    // NavbarView,
    // FooterView,
    // TapTop,
  },
  computed: {
  },

  beforeDestroy() {
    this.this.$store.state.user = null;
  },

  created() {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      // Update Vuex store with user data
      this.$store.commit("setUser", JSON.parse(user));
      // Optionally, validate token on the server-side and handle expiration
    } 
    else if (this.$route.path !== "/login") {
      // Redirect to login only if not already on login page
      this.$router.push("/coming-soon").catch(err => {
        // Handle navigation errors
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
    }
  }

  // beforeRouteEnter(to, from, next) {
  //   if (!localStorage.getItem("token")) {
  //     next("/login");
  //   } else {
  //     next();
  //   }
  // },
  // ...other options...
};
</script>

<style lang="scss">
@import "vue-multiselect/dist/vue-multiselect.min.css";

// #app {
//   // display: flex;
//   // align-items: center;
//   // min-height: 100vh;
//   // /* background: linear-gradient(to right, #00853e, #83c5be); */
//   // /* background: url('./assets/images/bg.jpg'); */
//   // background: linear-gradient(
//   //     to bottom,
//   //     rgba(65, 184, 131, 0.5) 0%,
//   //     rgba(131, 197, 190, 0.5) 100%
//   //   ),
//   //   url("./assets/images/bg.jpg");
//   // background-position: center;
//   // background-size: cover;
//   // padding: 30px 0 0;
// }


.multiselect {
  min-height: 38px !important;
}

.multiselect__placeholder {
  padding: 0 !important;
  margin: 0 !important;
  color: #555555 !important;
  font-size: 1rem !important;
}

.multiselect__tags {
  cursor: pointer;
  padding: .5rem 0px 0px .75rem;
  border-radius: 10px !important;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s, ease-in-out;
  min-height: 38px;
}

.multiselect__tag {
  margin-right: 5px !important;
  margin-bottom: 0px !important;
}

.multiselect__select {
  width: 20px;
  height: 40px;
}

.multiselect--disabled {
  background: transparent;
}

.multiselect--disabled .multiselect__select {
  background: transparent;
}

.slogan-text {
  font-size: 10rem;
  font-weight: bold;
  font-style: italic;
  -webkit-text-stroke: 3px #00853e;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  white-space: nowrap;
}

.btn-secondary {
  background-color: #00853e !important;
  border-color: #00853e !important;
  font-weight: bold;
}

.btn-secondary:hover {
  background-color: #404041 !important;
  border-color: #404041 !important;
}

.img-fluid>.card-img,
.card-img-top {
  width: 300px !important;
  height: 250px !important;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .slogan-text {
    font-size: 9rem;
  }
}

@media (max-width: 991px) {
  .slogan-text {
    font-size: 7rem;
  }

  .col {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .slogan-text {
    font-size: 5.5rem;
  }
}

@media (max-width: 575px) {
  .slogan-text {
    font-size: 4.5rem;
  }
}

@media (max-width: 479px) {
  .slogan-text {
    font-size: 3.5rem;
  }

  ::v-deep .nav-item {
    margin-bottom: 0.5rem !important;
  }
}

@media (max-width: 375px) {
  .slogan-text {
    font-size: 3rem;
  }
}
</style>
