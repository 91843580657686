<template>
  <div class="overlay">
    <div v-bind:style="styles" class="spinner spinner--double-bounce">
      <div class="double-bounce1" v-bind:style="bounceStyle"></div>
      <div class="double-bounce2" v-bind:style="bounceStyle"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      default: "80px",
    },
    background: {
      default: "#00853e",
    },
    duration: {
      default: "1.7s",
    },
  },
  computed: {
    bounceStyle() {
      return {
        backgroundColor: this.background,
        animationDuration: this.duration,
      };
    },
    styles() {
      return {
        width: this.size,
        height: this.size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: relative;
  display: inline-block;
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: double-bounce 2s ease-in-out infinite;
}

.double-bounce2 {
  animation-delay: -1s;
  background-color: #404041 !important;
}

@keyframes double-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
</style>
