<template>
  <div class="main-container">
    <WebLoader v-if="loading" />
    <h1 class="slogan-text mt-3">FIELD FELLAS</h1>
    <b-container class="tab-container" v-if="turfs && turfs.length > 0">
      <b-tabs v-model="activeTab" card>
        <b-tab v-for="(turf, index) in turfs" :key="index" :title="turf.turf_name">
          <b-container class="mb-3">
            <b-row>
              <b-col md="4" class="mb-2 left-container">
                <div class="image-container">
                  <b-img :src="turf.turf_image" alt="Turf image"></b-img>
                </div>
                <div class="details-container mt-2">
                  <h4 class="text-center">Turf Details</h4>
                  <h6>
                    Day Type:
                    <span class="text-primary">{{
      turf.details.day_type == "peak" ? "Peak" : "Off-Peak"
    }}</span>
                  </h6>
                  <h6>
                    Peak Hour:
                    <span class="text-primary">{{
        turf.details.peak_hour_time
      }}</span>
                  </h6>
                  <h6>
                    Off-Peak Hour:
                    <span class="text-primary">{{
        turf.details.off_peak_hour_time
      }}</span>
                  </h6>
                  <h6>
                    Peak Hour Charges:
                    <span class="text-primary">{{
        turf.details.peak_hour_rate + " PKR"
      }}</span>
                  </h6>
                  <h6>
                    Off-Peak Hour Charges:
                    <span class="text-primary">{{
        turf.details.off_peak_hour_rate + " PKR"
      }}</span>
                  </h6>
                  <div class="text-center">

                    <!-- get directions link in which there will be lat lng in anchor link -->
                    <a clas="text-center"
                      :href="`https://www.google.com/maps/search/?api=1&query=${turf.location_lat},${turf.location_lng}`"
                      target="_blank" class="btn btn-primary mt-2">Get Directions</a>
                  </div>
                </div>
              </b-col>
              <b-col md="8">
                <h6>Selected Date is:</h6>
                <Datepicker class="date-picker" v-model="selectedDate" :config="datePickerConfig"
                  @input="fetchTurfDetailsOnDateChange" :disabledDates="disabledDates"></Datepicker>
                <h6>Timeslots are:</h6>
                <div class="timeslots">
                  <div class="timeslot" v-for="(timeSlot, timeSlotIndex) in turf.timeslotss" :key="timeSlotIndex"
                    @click="toggleTimeSlot(index, timeSlotIndex)" :class="{
      selected: isSelected(index, timeSlotIndex),
      tempBooked: isTempBooked(timeSlot),
      booked: isBooked(timeSlot),
    }" :style="getStyle(timeSlot)">
                    {{ timeSlot.time_slot }}
                  </div>
                </div>
                <div class="btn-container">
                  <b-button variant="success" @click="finalizeBooking(index)">Finalize Booking</b-button>
                  <b-button variant="danger" @click="backToClub">Back</b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
    </b-container>
    <b-container v-else class="pb-3">
      <b-row>
        <b-col>
          <h1 class="py-5 h-100 text-center d-flex justify-content-center align-items-center">
            <strong>
              <i class="fas fa-exclamation-triangle text-danger"></i>
              No Turf Found
            </strong>
          </h1>
        </b-col>
        <b-col md="12" class="text-center">
          <b-button @click="backToClub" variant="danger" class="my-5 btn-danger">Back to Club</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import { mapGetters } from "vuex";
import { BASE_URL } from "@/../config";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      selectedDate: new Date(),
      datePickerConfig: {
        dateFormat: "Y-m-d",
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      activeTab: 0,
      tabValue: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["turfDetails"]),
    turfs() {
      return this.turfDetails;
    },
  },

  watch: {},
  created() {
    const vendorId = this.turfDetails[0]?.vendor_id;
    let currentRoute = this.$route.name;
    let previousRoute = this.$route.meta.previousRoute;

    if (previousRoute === null) {
      previousRoute = currentRoute;
    }

    console.log("Current Route:", currentRoute);
    console.log("Previous Route:", previousRoute);

    if (currentRoute === previousRoute) {
      console.log("Same route, fetching data...");
      if (!vendorId) {
        console.log("No vendor ID found, fetching from API...");
        this.$showErrorMessageToast("No vendor ID found, please try again");
        this.fetchTurfDetailsOnDateChange(); // Fetch the data from the API if vendorId is not present in state
      } else {
        console.log("Vendor ID:", vendorId);
        this.fetchTurfDetailsOnDateChange(); // Always fetch updated data on page load/refresh
      }
    }
  },
  destroyed() {
    console.log("Turf Details cleared");
    this.$store.commit("setTurfDetails", []);
  },
  methods: {
    async fetchTurfDetailsOnDateChange() {
      this.loading = true;
      const vendorId = this.turfDetails[0]?.vendor_id;
      const selectedDate = moment(this.selectedDate).format("YYYY-MM-DD");
      console.log("Selected Date:", selectedDate);
      console.log("Vendor ID:", vendorId);
      try {
        const response = await axios.get(
          `${BASE_URL}/get-vendor-turfs/${vendorId}?date=${selectedDate}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const turfDetails = response.data.data;
        console.log("Fetching turf time details:", turfDetails);

        this.$store.commit("setTurfDetails", turfDetails);
      } catch (error) {
        console.error("Error fetching turf details:", error);
      } finally {
        this.loading = false;
      }
    },
    toggleTimeSlot(tabIndex, timeSlotIndex) {
      const timeSlot = this.turfs[tabIndex].timeslotss[timeSlotIndex];
      if (
        timeSlot.timeslot_status === "temp_booked" ||
        timeSlot.timeslot_status === "booked"
      ) {
        return; // Do nothing if the timeslot is temp_booked or booked
      }

      const selectedTimeSlots = this.turfs[tabIndex].selectedTimeSlots || [];
      if (!selectedTimeSlots.includes(timeSlotIndex)) {
        selectedTimeSlots.push(timeSlotIndex);
      } else {
        selectedTimeSlots.splice(selectedTimeSlots.indexOf(timeSlotIndex), 1);
      }
      this.$set(this.turfs[tabIndex], "selectedTimeSlots", selectedTimeSlots);
    },
    isSelected(tabIndex, timeSlotIndex) {
      return (this.turfs[tabIndex].selectedTimeSlots || []).includes(
        timeSlotIndex
      );
    },
    isTempBooked(timeSlot) {
      return timeSlot.timeslot_status === "temp_booked";
    },
    isBooked(timeSlot) {
      return timeSlot.timeslot_status === "booked";
    },
    getStyle(timeSlot) {
      if (timeSlot.timeslot_status === "temp_booked") {
        return { backgroundColor: "#ffc107", cursor: "not-allowed" };
      } else if (timeSlot.timeslot_status === "booked") {
        return { backgroundColor: "#dc3545", cursor: "not-allowed" };
      }
      return {};
    },
    async finalizeBooking(tabIndex) {
      const selectedSlots = (this.turfs[tabIndex].selectedTimeSlots || []).map(
        (index) => this.turfs[tabIndex].timeslotss[index].id
      );

      if (selectedSlots.length === 0) {
        this.$showErrorMessageToast(
          "Please select a timeslot to proceed further"
        );
        return;
      }
      // this.loading = true;

      const formattedDate = moment(this.selectedDate).format("YYYY-MM-DD");

      let formData = new FormData();
      formData.append("vendor_id", this.turfs[tabIndex].vendor_id);
      formData.append("turf_id", this.turfs[tabIndex].id);
      formData.append("customer_id", this.$store.state.user.id);
      formData.append("time_slot_ids", selectedSlots);
      formData.append("date", formattedDate);

      try {
        const response = await axios.post(
          `${BASE_URL}/create-booking`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        this.$showSuccessMessageToast(response.data.message);

        // Fetch the updated turf details after successful booking
        await this.$store.dispatch(
          "fetchTurfDetails",
          this.turfs[tabIndex].vendor_id
        );

        // Log the vendor and turf details to the console
        // console.log("Turf Details:", this.turfs[tabIndex]);
        // this.setTimeout(() => {

        // }, timeout);
        // this.$router.push({
        //   name: "booking",
        // });
      } catch (error) {
        this.$showErrorMessageToast(error.response.data.error);
      }
      this.loading = false;
    },

    backToClub() {
      this.$router.push("/club");
    },
  },
};
</script>

<style scoped>
::v-deep .card-header-tabs {
  margin-left: 5px !important;
}

::v-deep .card-header-tabs .nav-link.active {
  color: white !important;
  background: #00853e !important;
}

@keyframes moveHeading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

::v-deep .card-header {
  margin-bottom: 1rem;
}

::v-deep .nav-link {
  border: 1px solid #dee2e6 !important;
  color: #00853e !important;
}

.card-gap {
  gap: 3rem;
}

::v-deep .nav-tabs {
  border: none !important;
}

::v-deep .nav-tabs li {
  padding: 0.5rem;
}

::v-deep .nav-link {
  max-width: 140px !important;
  white-space: nowrap !important;
}

::v-deep .nav-link.active {
  background-color: #404041 !important;
  color: white !important;
}

img {
  width: 100%;
  border-radius: 10px;
}

.timeslots {
  display: flex;
  flex-wrap: wrap;
}

.timeslot {
  cursor: pointer;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  text-align: center;
}

.selected {
  background-color: #00853e !important;
  color: white !important;
}

.btn-container {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-left: 5px;
}

::v-deep .date-picker>div>input {
  padding: 0.5rem !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  margin-bottom: 0.5rem !important;
  margin-left: 5px;
}

::v-deep .disabled {
  cursor: not-allowed !important;
}

::v-deep .vdp-datepicker__calendar .cell.selected {
  background-color: #00853e !important;
  color: white !important;
}

::v-deep .vdp-datepicker__calendar .cell.selected:hover {
  background-color: #00853e !important;
  color: white !important;
}

::v-deep .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border: 1px solid #404041 !important;
}

::v-deep .vdp-datepicker__calendar {
  border-radius: 5px !important;
}

.image-container {
  height: 250px;
  width: 350px;
  overflow: hidden;
}

.image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-primary {
  color: #00853e !important;
}

.details-container {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
