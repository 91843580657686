<template>
  <div class="mb-3" v-if="this.$store.state.user.role_type !== 'admin'">
    <h4>Booking Record</h4>
    <b-card>
      <b-table
        show-empty
        responsive
        hover
        scrollable
        :items="bookings"
        :fields="filteredFields"
        class="custom-table"
      ></b-table>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../config";

export default {
  data() {
    return {
      bookings: [], // Start with an empty array
      tableFields: [
        { key: "id", label: "Booking Id", sortable: true },
        { key: "customer_name", label: "Customer Name", sortable: true },
        { key: "club_name", label: "Club Name", sortable: true },
        { key: "turf_name", label: "Turf Id", sortable: true },
        { key: "time_slots", label: "Time Slots", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "total_amount", label: "Total Amount", sortable: true },
        { key: "booking_status", label: "Status", sortable: true },
      ],
    };
  },
  computed: {
    filteredFields() {
      if (this.$store.state.user.role_type === "customer") {
        return this.tableFields.filter(
          (field) => field.key !== "customer_name"
        );
      } else if (this.$store.state.user.role_type === "service_provider") {
        return this.tableFields.filter((field) => field.key !== "club_name");
      }
      return this.tableFields;
    },
  },
  mounted() {
    if (this.$store.state.user.role_type !== "admin") {
      this.fetchBookings();
    }
  },
  methods: {
    async fetchBookings() {
      const roleType = this.$store.state.user.role_type;
      let apiUrl = `${BASE_URL}/get-customer-booking`; // Default API URL
      const requestData = {}; // Default request body (empty for POST requests)

      if (roleType === "service_provider") {
        apiUrl = `${BASE_URL}/get-vendor-bookings`; // API URL for service providers
        // You can include specific request data for the POST request here if needed
        // requestData = { ... };
      }

      try {
        const response =
          roleType === "service_provider"
            ? await axios.post(apiUrl, requestData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
            : await axios.get(apiUrl, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

        if (response.data.success) {
          this.bookings = response.data.data.map((booking) => ({
            id: booking.id,
            turf_name: booking.turf_name,
            club_name: booking.club_name,
            // time_slots: booking.time_slot_ids.split(',').join(', '),  // Convert time slot IDs to comma-separated string
            time_slots: booking.timeslots
              .map((slot) => slot.time_slot)
              .join(", "),
            date: booking.date,
            total_amount: `Rs ${booking.total_amount}`,
            booking_status:
              booking.booking_status === "temp_booked"
                ? "Payment Pending"
                : booking.booking_status,
            customer_name: booking.customer_name,
          }));
        } else {
          // Handle error or empty state
          console.log("No bookings found");
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    },
  },
};
</script>

<style scoped>
::v-deep td {
  padding: 0.35rem 0.5rem !important;
  /* Adjust the padding to change row height */
  font-size: 14px;
  /* background: #f5f5f5 !important; */
}

::v-deep th {
  font-weight: 600;
  font-size: 14px;
  color: #404041 !important;
}

h4 {
  font-weight: 600;
  text-align: center;
}

.card-body {
  padding: 0px;
}

.card {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.custom-table.table-responsive {
  margin-bottom: 0px;
  max-height: 300px !important;
  overflow-y: auto;
}
</style>
