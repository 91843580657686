<template>
    <div class="video-container">
      <!-- MP4 Video -->
      <video class="background-video" autoplay muted loop playsinline>
        <source src="@/assets/coming-soon.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoPage',
  };
  </script>
  
  <style scoped>
  .video-container {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: #000;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>
  