<template>
  <b-container id="mainContainer" align-v="center">
    <WebLoader v-if="loading" />
    <CustomBg />
    <b-row align-v="center" align-h="center">
      <b-col md="12" class="mt-4 mb-4">
        <div>
          <b-card id="cardLogin" class="scale-in-bl">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show" id="formLogin">
              <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" class="blob">
                <path fill="#00853e"
                  d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                  transform="translate(100 100)" />
              </svg>

              <h1 class="text-center mb-3">Vendor Register</h1>
              <!-- <h2 class="text-center mb-4">Register with:</h2> -->
              <!-- <b-img src="../assets/images/logo.png" fluid class="logoLogin mb-4" /> -->
              <!-- <div class="d-flex justify-content-center mb-4">
                <img src="../assets/images/google.png" fluid class="icons" />
                <img
                  src="../assets/images/facebook.png"
                  fluid
                  class="icons mx-4"
                />
                <img src="../assets/images/twitter.png" fluid class="icons" />
              </div> -->
              <span class="text-center mb-4 other-account">Create your own account by filling the details below:</span>
              <b-row align-v="center" align-h="center">
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="name">
                    <b-form-input id="name" class="input" v-model="form.full_name" type="text" placeholder="Full Name"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="name">
                    <b-form-input id="name" class="input" v-model="form.club_name" type="text" placeholder="Club Name"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="email">
                    <b-form-input id="email" class="input" v-model="form.email" type="email" placeholder="E-mail"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="password">
                    <b-input-group class="password-input-group">
                      <b-form-input id="password" class="input" v-model="form.password"
                        :type="showPassword ? 'text' : 'password'" placeholder="Password" required></b-form-input>
                      <!-- Show/Hide Password Button -->
                      <b-input-group-append class="password-input-group-append">
                        <button class="btn eye-btn" type="button" @click="showPassword = !showPassword">
                          <i class="fas" :class="{
      'fa-eye': showPassword,
      'fa-eye-slash': !showPassword,
    }"></i>
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="password">
                    <b-input-group class="password-input-group">
                      <b-form-input id="confirm-password" class="input" v-model="form.password_confirmation"
                        :type="showConfirmPassword ? 'text' : 'password'" placeholder="Confirm Password"
                        required></b-form-input>
                      <!-- Show/Hide Password Button -->
                      <b-input-group-append class="password-input-group-append">
                        <button class="btn eye-btn" type="button" @click="showConfirmPassword = !showConfirmPassword">
                          <i class="fas" :class="{
      'fa-eye': showConfirmPassword,
      'fa-eye-slash': !showConfirmPassword,
    }"></i>
                        </button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="ntn">
                    <b-form-input id="ntn" class="input" v-model="form.ntn_number" type="text" placeholder="NTN Number"
                      required></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="contactNo">
                    <b-form-input id="contactNo" class="input" v-model="form.contact_number" type="tel"
                      placeholder="Contact No" required></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Cnic Number -->
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="cnicNumber">
                    <b-form-input id="cnicNumber" class="input" v-model="form.cnic_number" type="text"
                      placeholder="Cnic Number" required></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="locationPath">
                    <b-form-input id="locationPath" class="input" v-model="form.location_path" type="text"
                      placeholder="Location Url" required></b-form-input>
                  </b-form-group>
                </b-col> -->

                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1">
                    <b-form-select id="area-select" class="input" v-model="form.selectedArea" :options="areas"
                      value-field="area_name" text-field="area_name" required>
                      <template v-slot:first>
                        <option :value="null" disabled>Select Area</option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Upload Logo -->
                <b-col md="6">
                  <b-form-group class="pb-3" id="input-group-1" label-for="uploadLogo">
                    <b-form-file id="uploadLogo" class="input" accept=".jpg, .png, .jpeg" v-model="form.vendor_logo"
                      placeholder="Upload Club Logo" required></b-form-file>
                  </b-form-group>
                </b-col>



                <b-col md="12">
                  <multiselect class="mb-2" v-model="form.selectedFacilities" :options="facilities" :multiple="true"
                    :taggable="true" @tag="addTag" placeholder="Select one or more facilities (if any)" label="text"
                    track-by="value">
                  </multiselect>
                </b-col>

                <!-- Location Search Bar -->
                <b-col md="12">
                  <b-form-group class="pb-3" id="input-group-2" label-for="location">
                    <b-form-input id="location" class="input" v-model="form.location_name" type="text"
                      placeholder="Search Location" @focus="initAutocomplete"></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Google Map -->
                <b-col md="6">
                  <div id="map"></div>
                </b-col>
                <b-col md="6">
                </b-col>

                <!-- Hidden Inputs for Latitude and Longitude -->
                <input type="hidden" v-model="form.location_lat" />
                <input type="hidden" v-model="form.location_lng" />

              </b-row>

              <div class="d-flex justify-content-start align-items-baseline mt-2 cursor-pointer">
                <b-form-checkbox value="remember" class="remember"></b-form-checkbox>
                <div>
                  I have read and agree to the terms and conditions*
                </div>
              </div>

              <div class="d-flex justify-content-center mt-3">
                <a @click="onSubmit" href="#" class="register-arrow-btn">
                  <img class="arrow-img" src="@/assets/images/arrow.png" alt="" srcset="" />
                </a>
              </div>

              <div class="d-flex justify-content-center flex-wrap mt-4 register g-2">
                <span class="mr-2">Already have an account? </span>
                <a @click="goToLogin()" class="loginAccount">Go to Login</a>
              </div>
            </b-form>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CustomBg from "@/components/CustomBg";
import axios from "axios";
import { BASE_URL } from "@/../config";
import Multiselect from 'vue-multiselect'
/* global google */ // Add this to inform ESLint that 'google' is a global variable
export default {
  components: {
    CustomBg,
    Multiselect
  },
  data() {
    return {
      form: {
        full_name: "",
        club_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        ntn_number: "", // New field for NTN Number
        contact_number: "", // New field for Contact No
        cnic_number: "", // New field for CNIC Number
        vendor_logo: null,
        selectedArea: null, // New field for selected area
        checked: [],
        selectedFacilities: [],
        location_name: "",
        location_lat: "",
        location_lng: "",
      },
      map: null,
      marker: null,
      facilities: [],
      areas: [],
      show: true,
      showPassword: false,
      showConfirmPassword: false,
      loading: false,
    };
  },
  beforeMount() {
    this.fetchFacilities();
  },
  mounted() {
    this.loadGoogleMapsAPI();
  },
  created() {
    this.fetchAreas();
  },
  methods: {

    loadGoogleMapsAPI() {
      const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = this.initMap;
      document.head.appendChild(script);
    },
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 31.5204, lng: 74.3587 }, // Default to Lahore, Pakistan
        zoom: 13,
      });

      this.marker = new google.maps.Marker({
        map: this.map,
        draggable: true,
      });

      // Listen for drag events on the marker
      this.marker.addListener("dragend", () => {
        const position = this.marker.getPosition();
        this.form.location_lat = position.lat();
        this.form.location_lng = position.lng();
      });
    },

    // initAutocomplete() {
    //   const input = document.getElementById("location");
    //   const autocomplete = new google.maps.places.Autocomplete(input);

    //   autocomplete.addListener("place_changed", () => {
    //     const place = autocomplete.getPlace();
    //     if (!place.geometry) {
    //       return;
    //     }
    //     this.form.location_name = place.formatted_address;
    //     this.form.location_lat = place.geometry.location.lat();
    //     this.form.location_lng = place.geometry.location.lng();

    //     // Update the marker's position
    //     this.marker.setPosition(place.geometry.location);
    //     this.map.setCenter(place.geometry.location);
    //     this.map.setZoom(15);
    //   });
    // },
    initAutocomplete() {
      const input = document.getElementById("location");
      const autocomplete = new google.maps.places.Autocomplete(input, {
        // Restrict results to locations within Pakistan
        componentRestrictions: { country: "pk" }
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }

        // Ensure the full address includes both the place name and the formatted address
        let fullAddress = '';

        if (place.name) {
          fullAddress += place.name; // Use the place name (e.g., "Cricket Warriors")
        }

        if (place.formatted_address) {
          if (fullAddress) {
            fullAddress += ', '; // Add a comma if there's already a place name
          }
          fullAddress += place.formatted_address; // Add the formatted address
        }

        // Set the full address to the form
        this.form.location_name = fullAddress;

        // Update latitude and longitude
        this.form.location_lat = place.geometry.location.lat();
        this.form.location_lng = place.geometry.location.lng();

        // Update the marker's position
        this.marker.setPosition(place.geometry.location);
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(15);
      });
    },
    addTag(newTag) {
      console.log('New tag:', newTag)
      const tag = {
        text: newTag,
        value: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.facilities.push(tag)
      this.form.selectedFacilities.push(tag)
      // console.log('Facilities:', this.form.selectedFacilities)
    },
    fetchAreas() {
      axios
        .get("http://127.0.0.1:8000/api/get-lahore-areas")
        .then((response) => {
          this.areas = this.areas = response.data.data.sort((a, b) =>
            a.area_name.localeCompare(b.area_name)
          );
        })
        .catch((error) => {
          console.error("Error fetching areas:", error);
        });
    },
    fetchFacilities() {
      axios
        .get(`${BASE_URL}/get-facilities`)
        .then((response) => {
          this.facilities = response.data.data;
          console.log("Facilities are:", this.facilities);
        })
        .catch((error) => {
          console.error("Error fetching facilities:", error);
        });
    },

    onSubmit(event) {
      this.loading = true;
      event.preventDefault();
      // Create a FormData object
      let formData = new FormData();
      // Append form data to the FormData object
      formData.append("full_name", this.form.full_name);
      formData.append("club_name", this.form.club_name);
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      formData.append("password_confirmation", this.form.password_confirmation);
      formData.append("ntn_number", this.form.ntn_number);
      formData.append("contact_number", this.form.contact_number);
      formData.append("cnic_number", this.form.cnic_number);
      formData.append("vendor_logo", this.form.vendor_logo); // Append logo file
      formData.append("area_name", this.form.selectedArea); // Append selected area ID
      formData.append("location_path", this.form.location_path); // Append location path
      // formData.append("facilities", JSON.stringify(this.form.selectedFacilities.map(f => f.value)));
      // Convert the array to a comma-separated string
      formData.append('facilities', this.form.selectedFacilities.map(f => f.value).join(','));
      formData.append("location_name", this.form.location_name);
      formData.append("location_lat", this.form.location_lat);
      formData.append("location_lng", this.form.location_lng);
      // Append the comma-separated string to FormData
      // formData.append('facilities', facilitiesString);


      // Make the API request to create a vendor
      axios
        .post(`${BASE_URL}/create-vendor`, formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type for file upload
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          this.$showSuccessMessageToast(response.data.message);

          // Optionally, you can redirect to another page or show a success message
        })
        .catch((error) => {
          // Optionally, you can show an error message to the user
          this.$showErrorMessageToast(error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToLogin() {
      this.$router.push({ name: "login" });
    },

    onReset(event) {
      event.preventDefault();
      // Reset form values
      this.form.full_name = "";
      this.form.club_name = "";
      this.form.email = "";
      this.form.password = "";
      this.form.password_confirmation = "";
      this.form.ntn_number = "";
      this.form.contact_number = "";
      this.form.cnic_number = "";
      this.form.vendor_logo = null;
      this.form.selectedArea = null; // Reset selected area
      this.form.location_path = ""; // Reset location path
      this.form.selectedFacilities = []; // Reset selected facilities
      this.form.checked = [];
      this.form.location_name = "";
      this.form.location_lat = "";
      this.form.location_lng = "";
      this.marker.setPosition(null);
      this.map.setCenter({ lat: 31.5204, lng: 74.3587 }); // Default to Lahore, Pakistan
      this.map.setZoom(13);
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 1px solid #f5f5f5;
}

.password-input-group-append {
  position: absolute;
  right: 0;
}

.password-input-group .btn {
  border-color: none;
  background-color: transparent;
  border: none;
}

#mainContainer {
  width: 100%;
  height: auto;
  position: relative;
}

@import "@/assets/scss/animations.scss";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/fonts.scss";

.custom-file-input {
  display: none;
}

.b-form-file,
.custom-select {
  height: 37.34px;
}

.custom-file-label {
  cursor: pointer;
  width: 100%;
  padding: 0.375rem 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background-color: #fff;
  color: #555555;
}

.upload-btn {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 10px !important;
  background-color: #404041 !important;
}

.upload-btn:hover {
  background-color: #555555 !important;
}

body {
  font-family: $Poppins !important;
}

.g-2 {
  gap: 0.2rem;
}

.arrow-img {
  height: 3rem;
}

#cardLogin {
  border-radius: 15px;
  box-shadow: 0px 0px 10px $gray;

  #formLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
      color: $primary;
      font-weight: 700;
      font-family: $Poppins;
    }

    h2 {
      font-size: 1.2rem;
      color: $lightBlack;
      font-weight: 600;
      font-family: $Poppins;
    }

    .other-account {
      color: $gray;
    }

    .icons {
      width: 30px;
      cursor: pointer;
    }

    .input {
      border-radius: 10px;
    }

    .remember {
      color: $lightBlack;
    }

    .blob {
      position: absolute;
      top: -15%;
      left: -12%;
      width: 150px;
      opacity: 0.2;
    }
  }

  .forgotPassword {
    color: $gray;
    text-decoration: none;

    &:hover {
      color: $primary;
      transition: 0.5s ease-in-out;
    }
  }

  .register-arrow-btn {
    border-radius: 15px;
    background: $primary;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: $secondary;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }
  }

  .register {
    span {
      color: $lightBlack;
    }

    .loginAccount {
      color: $primary;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
