<template>
  <div class="main-container d-flex flex-responsive">
    <div>
      <b-sidebar v-if="vendors && vendors.length > 0" v-model="sidebarOpen" id="sidebar-variant" class="sidebar-title"
        title="Select Area" bg-variant="dark" text-variant="light" shadow>
        <!-- <div class="px-3 py-2"> -->
        <b-list-group class="list-option">
          <b-list-group-item class="list-option-item" v-for="tab in vendors" :key="tab.id" @click="activeTab = tab.id"
            :class="{ 'active-area': tab.id === activeTab }">
            {{ tab.title }}
          </b-list-group-item>
        </b-list-group>
        <!-- </div> -->

      </b-sidebar>
    </div>
    <WebLoader v-if="loading" />
    <b-container class="tab-container" v-if="vendors && vendors.length > 0">
      <b-row>
        <b-col md="4" class="offset-md-4">
          <b-form-input class="position-relative" v-model="searchTerm" placeholder="Search club"></b-form-input>
          <template v-if="filteredClubs.length > 0">
            <b-list-group class="mt-1 position-absolute club-option">
              <b-list-group-item v-for="(club, index) in filteredClubs" :key="index" @click="goToClub(club)"
                class="d-flex justify-content-between align-items-center club-option-list">
                {{ club.clubName }} - {{ getVendorName(club.vendorId) }}
                <!-- <b-badge variant="secondary">Go to Club</b-badge> -->
              </b-list-group-item>
            </b-list-group>
          </template>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex flex-wrap card-gap">
            <b-card v-for="(item, index) in getActiveTabClubs" :key="index"
              :class="{ 'highlighted-club': item.clubId === selectedClubId }"
              :img-src="item.imageUrl || 'https://5.imimg.com/data5/SELLER/Default/2023/2/GQ/AT/BR/56285870/football-turf.jpg'"
              img-alt="Club Image" img-top :title="item.clubName" class="mb-2 img-fluid img-responsive">
              <!-- <p class="card-text">{{ item.description }}</p> -->
              <b-button @click="() => goToSlot(activeTab, item.clubId, item.user_id)" class="book-btn">Book
                Now</b-button>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else class="pb-3">
      <b-row>
        <b-col>
          <h1 class="slogan-text mt-3">FIELD FELLAS</h1>
        </b-col>
        <b-col>
          <h1 class="py-5 h-100 text-center d-flex justify-content-center align-items-center">
            <strong>
              <i class="fas fa-exclamation-triangle text-danger"></i>
              No Clubs Found
            </strong>
          </h1>
        </b-col>
        <b-col md="12" class="text-center">
          <b-button @click="$router.push({ name: 'home' })" variant="danger" class="my-5 btn-danger">Back to
            Home</b-button>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WebLoader from '@/components/WebLoader.vue';

export default {
  data() {
    return {
      sidebarOpen: true,
      activeTab: 1,
      loading: false,
      searchTerm: '', // Added searchTerm to track the search input
      selectedClubId: null,

    };
  },
  components: {
    WebLoader,
  },
  computed: {
    ...mapGetters(['vendors']),
    // Filter vendors based on search term
    // Filter the clubs based on the search term
    filteredClubs() {
      if (this.searchTerm === '') return [];

      let allClubs = [];
      this.vendors.forEach(vendor => {
        allClubs = [...allClubs, ...vendor.turf_details.map(club => ({ ...club, vendorId: vendor.id }))];
      });

      return allClubs.filter(club =>
        club.clubName.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    getActiveTabClubs() {
      const activeTab = this.vendors.find((vendor) => vendor.id === this.activeTab);
      return activeTab ? activeTab.turf_details : [];
    },
  },
  methods: {
    ...mapActions(['fetchVendors', 'fetchTurfDetails']),
    goToClub(club) {
      const vendor = this.vendors.find(v => v.turf_details.some(t => t.clubId === club.clubId));
      console.log('Vendor is:', vendor);
      if (vendor) {
        this.activeTab = vendor.id; // Set the active tab based on the club's vendor
        this.searchTerm = ''; // Clear the search term
        // this.$nextTick(() => {
        //   this.$router.push({
        //     name: 'turf',
        
        // and close the b-list-group
        // this.$nextTick(() => {
        //   this.$router.push({
        //     name: 'turf',
        //     params: { area_id: vendor.id, club_id: club.clubId },
        //   });
        // });
      }
    },
    getVendorName(vendorId) {
      const vendor = this.vendors.find(v => v.id === vendorId);
      console.log('Vendor is:', vendor.title);
      return vendor ? vendor.title : '';
    },
    async goToSlot(area_id, club_id, user_id) {
      this.loading = true;
      // console.log('Area ID:', area_id);
      //  if in local storage there is no key user then give error message
      if (!localStorage.getItem('user')) {
        this.$showErrorMessageToast('Please login to book a turf');
        this.loading = false;
        return;
      }

      const vendor = this.vendors.find((vendor) => vendor.id === area_id);
      console.log('Vendor is:', vendor);

      const turf = vendor.turf_details.find((turf) => turf.clubId === club_id);
      // console.log('Turf is:', turf);

      if (turf?.is_available === "no") {
        this.$showErrorMessageToast('The turf is currently unavailable');
        this.loading = false;
        return;
      }



      await this.fetchTurfDetails(user_id);
      this.$router.push({
        name: "turf",
        params: { area_id, club_id },
      });
      this.loading = false;
    },
  },
  watch: {
    // Watch for route changes to handle tab and club selection
    $route: {
      immediate: true,
      handler(newRoute) {
        const { area_id, club_id } = newRoute.params;

        if (area_id) {
          // Set the active tab based on the area_id (vendor id)
          this.activeTab = parseInt(area_id);
        }

        if (club_id) {
          // Set the selected club ID to highlight the correct club
          this.selectedClubId = parseInt(club_id);
        }
      }
    },
    selectedDate() {
      // console.log('Selected Date:', this.selectedDate);
      this.fetchTurfDetailsOnDateChange();
    },
  },

  created() {
    this.fetchVendors();
  },
};
</script>

<style scoped>
.highlighted-club {
  background-color: #78cda07d;
}

.timeslots {
  display: flex;
  flex-wrap: wrap;
}

.timeslot {
  cursor: pointer;
  width: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  text-align: center;
}

.time-slot-button {
  width: 100%;
  margin-top: 5px;
}

.selected {
  background-color: #00853e !important;
  color: white !important;
}

.time-slot-text {
  font-weight: bold;
}

::v-deep .card-header-tabs .nav-link.active {
  color: white !important;
  background: #00853e;
}

@keyframes moveHeading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

/* .slogan-text {
  font-size: 10rem;
  font-weight: bold;
  font-style: italic;
  -webkit-text-stroke: 3px #00853e;
  color: white;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8);
  white-space: nowrap;
} */

::v-deep .card-header {
  margin-bottom: 1rem;
}

::v-deep .nav-link {
  border: 1px solid #dee2e6 !important;
  color: #00853e !important;
}

.card-gap {
  margin-top: 1rem;
  gap: 1rem !important;
}

::v-deep .card-header {
  display: flex !important;
  justify-content: center !important;
}

/* .card {
  max-width: 50% !important;
} */

.book-btn {
  background-color: #404041;
  color: white;
}

/* @media (max-width: 1200px) {
  .slogan-text {
    font-size: 9rem;
  }
}

@media (max-width: 991px) {
  .slogan-text {
    font-size: 7rem;
  }
}

@media (max-width: 767px) {
  .slogan-text {
    font-size: 5.5rem;
  }
}

@media (max-width: 575px) {
  .slogan-text {
    font-size: 4.5rem;
  }
}

@media (max-width: 479px) {
  .slogan-text {
    font-size: 3.5rem;
  }

  ::v-deep .nav-item {
    margin-bottom: .5rem !important;
  }
}

@media (max-width: 375px) {
  .slogan-text {
    font-size: 3rem;
  }
} */

.club-option {
  overflow-y: auto;
  z-index: 1000;
  cursor: pointer;
}

.club-option-list:hover {
  background-color: #f8f9fa;
  color: #00853e;
}

.nav-item {
  padding-bottom: 0.5rem !important;
}

::v-deep .area-item>.card-header>.nav-tabs>.nav-item {
  padding-bottom: 0.5rem !important;
}

/* i want to remove the position of .b-sidebar and .b-sidebar-outside from the css file */

::v-deep .b-sidebar {
  position: relative !important;
  cursor: pointer !important;
  width: 230px !important;
}

::v-deep .b-sidebar-outer {
  position: relative !important;
  height: 100% !important;
}

::v-deep .b-sidebar>.b-sidebar-header .close {
  display: none !important;
}

/* Customize the scrollbar */
::v-deep .b-sidebar>.b-sidebar-body::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
  height: 8px;
  /* Set the height of the scrollbar */
  background-color: #f5f5f5;
  /* Set the background color of the scrollbar */
}

::v-deep .b-sidebar>.b-sidebar-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Set the background color of the scrollbar thumb */
}

::v-deep .b-sidebar>.b-sidebar-body::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
  /* Set the background color of the scrollbar thumb on hover */
}

::v-deep .b-sidebar>.b-sidebar-body::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* Set the background color of the scrollbar track */
}

.b-sidebar>.b-sidebar-header {
  background-color: #404041 !important;
  color: rgb(41, 4, 4) !important;
}

.active-area {
  background-color: #00853e !important;
  color: #fff;
  transition: background-color 0.3s ease;
  /* optional: change the text color to white */
}

.list-option {
  border-radius: 0px !important;
}

.list-option-item {
  border-radius: 0px !important;
  border: none !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.list-option-item:hover {
  background-color: #eae8e8 !important;
  color: #00853e !important;
}

/* .sidebar-variant___title__{
  color: red !important;
} */

/* ::v-deep .sidebar-title>.b-sidebar>.b-sidebar-header {
  color: #00853e !important;
} */

::v-deep .bg-dark {
  background-color: #404041 !important;
}

@media screen and (max-width: 575px) {
  .flex-responsive {
    flex-direction: column;
  }

  ::v-deep .b-sidebar {
    width: 100% !important;
    margin-bottom: 1rem !important;
    max-height: 300px !important;
  }
}
</style>
