import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import './assets/css/app.css';
import VueCarousel from 'vue-carousel';
import VueTheMask from 'vue-the-mask'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import WebLoader from './components/WebLoader.vue';
import ToggleButton from 'vue-js-toggle-button'
const toastOptions = {
  position: "top-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueCarousel);
Vue.use(VueTheMask);
Vue.use(Toast, toastOptions);
Vue.component('WebLoader', WebLoader);
Vue.use(ToggleButton);

// Define toast methods globally
Vue.prototype.$showSuccessMessageToast = function(message) {
  this.$toast.success(message);
};

Vue.prototype.$showErrorMessageToast = function(message) {
  this.$toast.error(message);
};

Vue.prototype.$http = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
