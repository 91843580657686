<template>
  <div class="contact">
    <ContactPage />
  </div>
</template>

<script>
// @ is an alias to /src
import ContactPage from "@/components/ContactPage.vue";

export default {
  name: "ContactView",
  components: {
    ContactPage,
  },
};
</script>
