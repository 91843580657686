<template>
  <div class="main-container">
    <b-container class="my-3">
      <div class="row">
        <!-- <div class="col" md="6" sm="12">
          <BookingPayment :booking="booking" :turf="turf" />
        </div> -->
        <div class="col" md="6">
          <CreditCard />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import CreditCard from "@/components/CreditCard.vue";
import VueTheMask from "vue-the-mask";

export default {
  name: "HomeView",
  directives: {
    mask: VueTheMask.mask,
  },
  components: {
    CreditCard,
  },
  data() {
    return {
    };
  },
};
</script>
