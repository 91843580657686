var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"id":"mainContainer","align-v":"center"}},[(_vm.loading)?_c('WebLoader'):_vm._e(),_c('CustomBg'),_c('b-row',{attrs:{"align-v":"center","align-h":"center"}},[_c('b-col',{attrs:{"md":"6"}},[_c('div',[_c('b-card',{staticClass:"scale-in-bl",attrs:{"id":"cardPassword"}},[(_vm.show)?_c('b-form',{attrs:{"id":"formLogin"},on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('svg',{staticClass:"blob",attrs:{"viewBox":"0 0 200 200","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill":"#00853e","d":"M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z","transform":"translate(100 100)"}})]),_c('h1',{staticClass:"text-center mb-3"},[_vm._v("Change Password")]),_c('h2',{staticClass:"text-center mb-4"},[_vm._v(" Set a new unique password and leave the rest to us ")]),_c('b-form-group',{attrs:{"id":"input-group-1","label-for":"old_password"}},[_c('b-input-group',[_c('b-form-input',{staticClass:"input mb-3",attrs:{"id":"old_password","type":_vm.showOldPassword ? 'text' : 'password',"placeholder":"Old Password"},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}}),_c('b-input-group-append',[_c('button',{staticClass:"btn eye-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.showOldPassword = !_vm.showOldPassword}}},[_c('i',{staticClass:"fas",class:{
                        'fa-eye': _vm.showOldPassword,
                        'fa-eye-slash': !_vm.showOldPassword,
                      }})])])],1)],1),_c('b-form-group',{attrs:{"id":"input-group-2","label-for":"new_password"}},[_c('b-input-group',[_c('b-form-input',{staticClass:"input mb-3",attrs:{"id":"new_password","type":_vm.showNewPassword ? 'text' : 'password',"placeholder":"New Password"},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('b-input-group-append',[_c('button',{staticClass:"btn eye-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.showNewPassword = !_vm.showNewPassword}}},[_c('i',{staticClass:"fas",class:{
                        'fa-eye': _vm.showNewPassword,
                        'fa-eye-slash': !_vm.showNewPassword,
                      }})])])],1)],1),_c('b-form-group',{attrs:{"id":"input-group-3","label-for":"new_password_confirmation"}},[_c('b-input-group',[_c('b-form-input',{staticClass:"input mb-3",attrs:{"id":"new_password_confirmation","type":_vm.showConfirmPassword ? 'text' : 'password',"placeholder":"Confirm New Password"},model:{value:(_vm.form.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "new_password_confirmation", $$v)},expression:"form.new_password_confirmation"}}),_c('b-input-group-append',[_c('button',{staticClass:"btn eye-btn",attrs:{"type":"button"},on:{"click":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}}},[_c('i',{staticClass:"fas",class:{
                        'fa-eye': _vm.showConfirmPassword,
                        'fa-eye-slash': !_vm.showConfirmPassword,
                      }})])])],1)],1),_c('div',{staticClass:"d-flex justify-content-center mt-3",on:{"click":_vm.onSubmit}},[_c('a',{staticClass:"recover-arrow-btn",attrs:{"href":"#"}},[_c('img',{staticClass:"arrow-img",attrs:{"src":require("../assets/images/arrow.png"),"alt":"","srcset":""}})])]),_c('div',{staticClass:"d-flex justify-content-center flex-wrap mt-4 register g-2"},[_c('span',{staticClass:"mr-2"},[_vm._v("Have you already changed your password? ")]),_c('a',{staticClass:"loginAccount",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" To Login")])])],1):_vm._e()],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('img',{staticClass:"businessImg scale-in-bottom",attrs:{"src":require("../assets/images/business-coffee.png"),"fluid":"","center":""}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }