<template>
  <b-container id="mainContainer" align-v="center">
    <WebLoader v-if="loading" />

    <CustomBg />
    <b-row align-v="center" align-h="center">
      <b-col md="6">
        <div>
          <b-card id="cardPassword" class="scale-in-bl">
            <b-form
              @submit="onSubmit"
              @reset="onReset"
              v-if="show"
              id="formLogin"
            >
              <svg
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
                class="blob"
              >
                <path
                  fill="#00853e"
                  d="M66.2,-49.1C81.5,-33.5,86.8,-6.2,79.8,15.6C72.7,37.5,53.3,53.9,30.9,65.1C8.5,76.2,-16.9,81.9,-36.2,73.3C-55.5,64.7,-68.8,41.7,-70.8,19.9C-72.8,-2,-63.6,-22.7,-49.9,-37.8C-36.2,-53,-18.1,-62.7,3.6,-65.6C25.4,-68.5,50.8,-64.6,66.2,-49.1Z"
                  transform="translate(100 100)"
                />
              </svg>

              <h1 class="text-center mb-3">Change Password</h1>
              <h2 class="text-center mb-4">
                Set a new unique password and leave the rest to us
              </h2>

              

              <!-- Old Password -->
              <b-form-group id="input-group-1" label-for="old_password">
                <b-input-group>
                  <b-form-input
                    id="old_password"
                    class="input mb-3"
                    v-model="form.old_password"
                    :type="showOldPassword ? 'text' : 'password'"
                    placeholder="Old Password"
                    
                  ></b-form-input>
                  <!-- Show/Hide Password Button -->
                  <b-input-group-append>
                    <button
                      class="btn eye-btn"
                      type="button"
                      @click="showOldPassword = !showOldPassword"
                    >
                      <i
                        class="fas"
                        :class="{
                          'fa-eye': showOldPassword,
                          'fa-eye-slash': !showOldPassword,
                        }"
                      ></i>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <!-- New Password -->
              <b-form-group id="input-group-2" label-for="new_password">
                <b-input-group>
                  <b-form-input
                    id="new_password"
                    class="input mb-3"
                    v-model="form.new_password"
                    :type="showNewPassword ? 'text' : 'password'"
                    placeholder="New Password"
                    
                  ></b-form-input>
                  <!-- Show/Hide Password Button -->
                  <b-input-group-append>
                    <button
                      class="btn eye-btn"
                      type="button"
                      @click="showNewPassword = !showNewPassword"
                    >
                      <i
                        class="fas"
                        :class="{
                          'fa-eye': showNewPassword,
                          'fa-eye-slash': !showNewPassword,
                        }"
                      ></i>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <!-- Confirm New Password -->
              <b-form-group
                id="input-group-3"
                label-for="new_password_confirmation"
              >
                <b-input-group>
                  <b-form-input
                    id="new_password_confirmation"
                    class="input mb-3"
                    v-model="form.new_password_confirmation"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    placeholder="Confirm New Password"
                    
                  ></b-form-input>
                  <!-- Show/Hide Password Button -->
                  <b-input-group-append>
                    <button
                      class="btn eye-btn"
                      type="button"
                      @click="showConfirmPassword = !showConfirmPassword"
                    >
                      <i
                        class="fas"
                        :class="{
                          'fa-eye': showConfirmPassword,
                          'fa-eye-slash': !showConfirmPassword,
                        }"
                      ></i>
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <div @click="onSubmit" class="d-flex justify-content-center mt-3">
                <a href="#" class="recover-arrow-btn">
                  <img
                    class="arrow-img"
                    src="../assets/images/arrow.png"
                    alt=""
                    srcset=""
                  />
                </a>
              </div>

              <div
                class="d-flex justify-content-center flex-wrap mt-4 register g-2"
              >
                <span class="mr-2"
                  >Have you already changed your password?
                </span>
                <a @click="$router.go(-1)" class="loginAccount"> To Login</a>
              </div>
            </b-form>
          </b-card>
        </div>
      </b-col>
      <b-col md="6">
        <img
          src="../assets/images/business-coffee.png"
          fluid
          class="businessImg scale-in-bottom"
          center
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import CustomBg from "../components/CustomBg";
import { BASE_URL } from "../../config";
import WebLoader from "./WebLoader.vue";

export default {
  components: {
    CustomBg,
    WebLoader,
  },
  data() {
    return {
      form: {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
        user_id: this.$store.state.user.id,
      },
      show: true,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      loading: false,
    };
  },
  methods: {
    onSubmit(event) {
      this.loading = true;
      // console.log("Form submitted:", this.form);
      event.preventDefault();

      axios
        .post(`${BASE_URL}/change-password`, this.form, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          // console.log("Password changed successfully:", response.data);
          this.$showSuccessMessageToast(response.data.message);

          this.$nextTick(() => {
            this.form.old_password = "";
            this.form.new_password = "";
            this.form.new_password_confirmation = "";
          });
        })
        .catch((error) => {
          this.$showErrorMessageToast(error.response.data.error);
          console.error("Error changing password:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onReset(event) {
      event.preventDefault();
      this.form.old_password = "";
      this.form.new_password = "";
      this.form.new_password_confirmation = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/animations.scss";
@import "../assets/scss/variables.scss";
@import "../assets/scss/fonts.scss";

.input-group-append {
  position: absolute;
  right: 0;

}

.input-group .btn {
  border-color: none;
  background-color: transparent;
  border: none;
}

.input {
  z-index: 1 !important;
}

.fa-eye,
.fa-eye-slash {
  color: $gray;
  cursor: pointer;
}

#mainContainer {
  width: 100%;
  height: auto;
  position: relative;
}

.g-2 {
  gap: 0.2rem;
}

body {
  font-family: $Poppins !important;
}

.arrow-img {
  height: 3rem;
}

#cardPassword {
  border-radius: 15px;
  box-shadow: 0px 0px 10px $gray;

  #formLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;

    h1 {
      color: $primary;
      font-weight: 700;
      font-family: $Poppins;
    }

    h2 {
      font-size: 1.2rem;
      color: $lightBlack;
      font-weight: 600;
      font-family: $Poppins;
    }

    .other-account {
      color: $gray;
    }

    .icons {
      width: 30px;
      cursor: pointer;
    }

    .input {
      border-radius: 10px;
    }

    .remember {
      color: $lightBlack;
    }

    .blob {
      position: absolute;
      top: -15%;
      left: -12%;
      width: 150px;
      opacity: 0.2;
    }
  }

  /* .logoLogin {
      margin: 0 auto;
      width: 100px;
    } */

  .forgotPassword {
    color: $gray;
    text-decoration: none;

    &:hover {
      color: $primary;
      transition: 0.5s ease-in-out;
    }
  }

  /* .btn-login {
      width: 200px;
      background-color: $primary;
      border: none;
      font-size: 1.3rem;
      font-weight: 600;
      border-radius: 10px;
  
      &:hover {
        background-color: $secondary;
        transition: 0.5s ease-in-out;
      }
    } */

  .recover-arrow-btn {
    // width: 100px;
    border-radius: 15px;
    background: $primary;
    // color: #fff;
    // font-size: 60px;
    // padding: 5px;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      background: $secondary;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }
  }

  .register {
    span {
      color: $lightBlack;
    }

    .loginAccount {
      color: $primary;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
