<template>
  <div class="countdown">
    <div class="time">
      <span>{{ hours }}</span> Hours
    </div>
    <div class="time">
      <span>{{ minutes }}</span> Mins
    </div>
    <div class="time">
      <span>{{ seconds }}</span> Sec
    </div>
  </div>
</template>

<script>
export default {
  props: {
    endTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeLeft: this.calculateTimeLeft(),
    };
  },
  computed: {
    hours() {
      return Math.floor(this.timeLeft / 3600);
    },
    minutes() {
      return Math.floor((this.timeLeft % 3600) / 60);
    },
    seconds() {
      return this.timeLeft % 60;
    },
  },
  methods: {
    calculateTimeLeft() {
      const endTimeDate = new Date(this.endTime).getTime();
      const now = new Date().getTime();
      return Math.floor((endTimeDate - now) / 1000);
    },
    startCountdown() {
      const countdownInterval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(countdownInterval);
          this.$emit('time-up');
        }
      }, 1000);
    },
  },
  mounted() {
    this.startCountdown();
  },
};
</script>

<style scoped>
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.time {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #404041;
  padding: 0.5rem;
  font-weight: 500;
  color: white;
  border-radius: 10px;
  min-width: 65px;
}
</style>
