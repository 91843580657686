<template>
  <b-container fluid>
    <WebLoader v-if="loading" />
    <b-row class="d-flex">
      <!-- First Column (4 columns wide on medium screens) -->
      <b-col md="4" class="bg-dark text-light p-4">
        <div class="text-center">
          <img src="../assets/sec-logo.svg" alt="Logo" />
          <p class="mt-2">
            Your <br />
            one stop <span class="sec-color"> booking solution </span>
          </p>
        </div>
      </b-col>

      <!-- Second Column (8 columns wide on medium screens) -->
      <b-col md="8" class="p-4 d-flex gray-bg">
        <!-- Map Image (overlay to the left) -->
        <img src="../assets/map-tp.png" alt="Map" class="map-img" />

        <!-- Contact Form (on the right side) -->
        <b-card class="w-100">
    <h2>GET IN TOUCH</h2>
    <p>WELCOME TO THE NEW ERA OF BOOKING</p>
    <b-form @submit.prevent="submitForm">
      <b-form-group class="pb-3" id="name">
        <b-form-input v-model="form.name" id="name" placeholder="Name" ></b-form-input>
      </b-form-group>

      <b-form-group class="pb-3" id="email">
        <b-form-input v-model="form.email" id="email" type="email" placeholder="Email" ></b-form-input>
      </b-form-group>

      <b-form-group class="pb-3" id="message">
        <b-form-textarea v-model="form.message" id="message" placeholder="Message" ></b-form-textarea>
      </b-form-group>

      <b-button type="submit" class="submit-btn"> SUBMIT</b-button>
    </b-form>
  </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ContactPage',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      loading: false
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      axios.post('http://127.0.0.1:8000/api/contact-details', this.form)
        .then(response => {
          // console.log('API Response:', response.data);
          // Optionally, you can handle success response here
          this.$showSuccessMessageToast(response.data.message);

        })
        .catch(error => {
          console.error('API Error:', error);
          this.$showErrorMessageToast(error.response.data.error);
        })
        .finally(() => {
          this.loading = false;
          this.clearForm();
        });
    },
    clearForm() {
      // Reset form data
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
    }
  }
};
</script>

<style scoped>
.container-fluid {
  animation: slideInFromBottom 0.8s ease; /* Apply the animation directly to the component */
}

/* Define the animation for the transition */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%); /* Start off-screen to the left */
  }
  100% {
    transform: translateY(0%); /* Slide in to the final position */
  }
}
.map-img {
  max-width: 55%;
  max-height: 85%;
  /* border-radius: 10px; */
  /* transform: translateX(-3rem); */
}

.card {
  background-color: transparent !important;
  border: none !important;
}

.card-body > h2 {
  font-weight: 700;
  color: #404041;
  margin: 0;
}

.card-body > p {
  color: #404041;
  font-size: 13px;
}

.text-center > p {
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.25;
}

.text-center > img {
  max-width: 13rem;
}

.sec-color {
  color: #00853e !important;
}

.card-body {
  padding: 0px !important;
}

textarea {
  height: 9rem !important;
  max-height: 9rem !important;
}

.gray-bg {
  background-color: #f5f5f5;
  align-items: center;
  /* background: linear-gradient(
      to bottom,
      rgba(65, 184, 131, 0.5) 0%,
      rgba(131, 197, 190, 0.5) 100%
    ),
    url("../assets/images/bg.jpg"); */
}

.submit-btn {
  background-color: #404041;
  font-weight: 800;
  color: white;
  border: none;
}

.submit-btn:hover {
  background-color: #00853e;
}
.form-control {
  border: transparent;
  box-shadow: 0px 2px 4px 0px #9999;
}

@media (max-width: 1199px) {
  .text-center > p {
    font-size: 3.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.25;
  }
}

@media (max-width: 991px) {
  /* .text-center > p {
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.25;
  } */
  /* .text-center > img {
    max-width: 11rem;
  } */

  /* .d-flex {
    flex-direction: column;
  } */

  .bg-dark {
    width: 100%;
  }

  .gray-bg {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .text-center > p {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.25;
  }

  .text-center > img {
    max-width: 11rem;
  }

  .map-img {
    max-width: 70%;
  }

  .gray-bg {
    display: flex;
    flex-direction: column;
  }
}
</style>
